"use client";

import { Children } from "react";

import useHome from "../../use-home";
import CardNewsByCategory from "./components/card-news-by-category/card-news-by-category";

const NewsByCategory = () => {
  const { categories } = useHome();
  return (
    <section className="w-full flex flex-col gap-size-x10 desktopLarge:flex-row">
      {categories.length > 0
        ? Children.toArray(
            categories.map((category) => {
              return <CardNewsByCategory key={category.id} data={category} />;
            })
          )
        : [0, 1, 2, 3].map((index) => <CardNewsByCategory key={index} />)}
    </section>
  );
};

export default NewsByCategory;
