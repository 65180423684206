import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/screens/home/components/featured-news/featured-news.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/screens/home/components/media-carousel/media-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/screens/home/components/news-by-category/news-by-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Root","SwitchThumb"] */ "/vercel/path0/node_modules/.pnpm/@radix-ui+react-switch@1.1.1_@types+react-dom@18.3.0_@types+react@18.3.11_react-dom@18.3.1_react@18.3.1/node_modules/@radix-ui/react-switch/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/advertising-banner/advertising-banner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/bank-slip-informations/bank-slip-informations.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/card-notification/card-notification.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/dock/dock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/form-login/form-login.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/loading-page/loading-page.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/containers/src/pix-informations/pix-informations.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/alert.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/success.svg");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionRoot","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/packages/ui/src/accordion/ui/accordion-ui.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/breadcrumb/breadcrumb.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/bundle-feedback-user/bundle-feedback-user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Loading"] */ "/vercel/path0/packages/ui/src/button/loading/loading.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/carousel/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/checkbox/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/dropdown-menu/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/dropzone/dropzone.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/editor/editor.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/form-page-buttons/form-page-buttons.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/label/label.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/modal/ui/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/multi-select/multi-select.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/radio-group/radio-group.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/scroll-area/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/search-bar/search-bar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/select/select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Pagination"] */ "/vercel/path0/packages/ui/src/table/pagination.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/tabs/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/text-area/text-area.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/text-field/text-field.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/tooltip/tooltip.tsx");
