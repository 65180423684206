import React from "react";

import { RadioGroupItem, Text } from "@aprosoja/ui";
import { formatCurrency } from "@aprosoja/utils";

interface PaymentRadioItemProps {
  title: string;
  value: string;
  annualPayment: string;
  children?: React.ReactNode;
}

export const PaymentRadioItem = ({
  children,
  title,
  value,
  annualPayment,
}: PaymentRadioItemProps) => (
  <div
    className={
      "flex flex-col w-full items-center border-th-color-neutral-200 border-border-width-thin rounded-border-radius-small px-size-x3 py-size-x4 gap-size-x6"
    }
  >
    <div className="flex w-full items-center justify-between">
      <div className="flex w-full items-center gap-size-x2">
        <RadioGroupItem value={value} />
        <Text type="labelL">{title}</Text>
      </div>
      <Text type="paragraphS" className="text-nowrap">
        Total{" "}
        <Text type="headingXS" color="th-primary-color-main">
          {formatCurrency(annualPayment)}
        </Text>
      </Text>
    </div>
    {children}
  </div>
);
