"use client";

import { AdvertisingBanner, CardNews } from "@aprosoja/containers";
import { useMediaQuery } from "@aprosoja/hooks";
import {
  BREAKPOINT_DESKTOP_LARGE,
  BREAKPOINT_TABLET_MEDIUM,
} from "@aprosoja/styles/breakpoints";
import { Text } from "@aprosoja/ui";

import NewsCarousel from "./components/news-carousel";
import * as s from "./styles";
import useHome from "../../use-home";

const FeaturedNews = () => {
  const { firstTwoRecentFeaturedNews, remainingRecentFeaturedNews } = useHome();
  const isTablet = useMediaQuery(`(min-width: ${BREAKPOINT_TABLET_MEDIUM})`);
  const isLargeDesktop = useMediaQuery(
    `(min-width: ${BREAKPOINT_DESKTOP_LARGE})`
  );

  return (
    <section className={s.section()}>
      <div className={s.firstRow()}>
        <CardNews
          variant="homeHighlight"
          hasImage
          data={firstTwoRecentFeaturedNews[0]}
        />
        <div className={s.container()}>
          <div className="w-full h-[320px] flex flex-col gap-size-x4 tabletSmall:w-auto">
            <Text color="th-secondary-color-dark" type="headingM">
              Cotação
            </Text>
            <div className="w-full h-full tabletSmall:w-[370px] desktopXlarge:w-[400px] bg-neutral-300">
              <iframe
                title="cotações"
                src="https://br.widgets.investing.com/live-commodities?theme=lightTheme&roundedCorners=true&pairs=8916,8918,8851"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <CardNews
            hasImage
            orientation={isLargeDesktop ? "vertical" : "horizontal"}
            data={firstTwoRecentFeaturedNews[1]}
            className="hidden tabletLarge:block"
          />
          <AdvertisingBanner
            type="middle"
            className="flex tabletLarge:hidden"
          />
        </div>
      </div>
      <div className={s.secondRow()}>
        <div className="w-fit order-2 flex-shrink-0 hidden tabletLarge:block desktopMedium:w-[352px]">
          <AdvertisingBanner type="middle" />
        </div>
        <div className="w-full block tabletLarge:hidden">
          <CardNews
            hasImage
            orientation={isTablet ? "horizontal" : "vertical"}
            data={firstTwoRecentFeaturedNews[1]}
          />
        </div>
        <NewsCarousel
          className="w-full order-1 flex-grow-0"
          data={remainingRecentFeaturedNews}
        />
      </div>
    </section>
  );
};

export default FeaturedNews;
