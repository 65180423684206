import { cva } from "class-variance-authority";

export const section = cva(
  "w-full h-full flex flex-col items-center gap-size-x10 tabletMedium:gap-size-x14"
);

export const firstRow = cva(
  "w-full flex flex-col gap-size-x10 tabletMedium:h-[978px] tabletMedium:gap-size-x14 desktopLarge:flex-row desktopLarge:h-[807px]"
);

export const secondRow = cva(
  `w-full flex flex-col gap-size-x10 tabletMedium:gap-size-x14
  tabletLarge:flex-row desktopMedium:gap-size-x10`
);

export const container = cva(
  `w-full flex flex-col items-center justify-center gap-size-x10 tabletSmall:flex-row
  tabletMedium:gap-size-x14 desktopLarge:flex-col desktopLarge:max-w-[400px] desktopLarge:gap-size-x4`
);
