import Image from "next/image";

import { Text } from "@aprosoja/ui";
import error from "../../public/404.gif";

const NotFound = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="w-full max-w-[300px] flex flex-col items-center text-center gap-size-x6">
        <Image src={error} alt="página não encontrada" />
        <Text type="headingS">Erro 404</Text>
        <Text type="paragraphM" className="text-center">
          Não encontramos a URL neste servidor. Isso é tudo que sabemos.
        </Text>
      </div>
    </div>
  );
};

export { NotFound };
