import React from "react";
import Link from "next/link";
import dayjs from "dayjs";
import Image from "next/image";

import { Skeleton, Text } from "@aprosoja/ui";
import { NewsType } from "@aprosoja/types";
import {
  cn,
  getBadgeCategoryColor,
  handleLimitCharacter,
  imageLoader,
} from "@aprosoja/utils";

type CardNewsProps = {
  data?: NewsType;
  hasImage?: boolean;
  variant?: "default" | "highlight" | "homeHighlight";
  orientation?: "vertical" | "horizontal";
  isLoading?: boolean;
  className?: string;
};

export function CardNews({
  data,
  hasImage = false,
  variant = "default",
  orientation = "vertical",
  isLoading = false,
  className,
}: CardNewsProps) {
  const imageHeightClasses =
    (variant === "highlight" &&
      "h-[328px] tabletSmall:h-[440px] desktopLarge:h-[877px]") ||
    (variant === "homeHighlight" &&
      "h-[328px] tabletSmall:h-[424px] desktopLarge:h-[630px]") ||
    "h-[289px] tabletSmall:h-[240px] desktopLarge:h-[289px]";

  const colorCategoryName =
    (data?.category?.color === "th-color-neutral-800" &&
      "th-color-neutral-0") ||
    (data?.category?.color === "th-color-neutral-900" &&
      "th-color-neutral-0") ||
    "th-color-neutral-700";

  return (
    <Link
      href={data ? `/noticias/${data.slug}` : "#"}
      className={cn(
        `w-full ${data ? "" : "pointer-events-none cursor-default"}`,
        className
      )}
    >
      <div
        className={`
          flex ${orientation === "vertical" ? "flex-col" : "flex-row"} gap-size-x4`}
      >
        {hasImage && (
          <div
            className={`relative w-full ${imageHeightClasses} ${orientation === "horizontal" ? "max-w-[320px]" : ""}`}
          >
            {isLoading || !data ? (
              <Skeleton className="w-full h-full bg-neutral-300" />
            ) : (
              <Image
                fill
                priority
                loader={imageLoader}
                placeholder="blur"
                blurDataURL={data.imageUrl}
                src={data?.imageUrl ?? ""}
                alt={data?.title || "Loading..."}
                className="w-full h-full object-cover rounded-border-radius-small"
                sizes="(max-width: 744px) 90vw, (max-width: 1280px) 50vw, 33vw"
              />
            )}
          </div>
        )}
        <div className="w-full flex flex-col gap-size-x4">
          <div className="flex items-center gap-size-x3 justify-between w-full">
            {isLoading || !data ? (
              <Skeleton className="w-[100px] h-[24px] bg-neutral-300" />
            ) : (
              <div
                className={`p-size-x2 bg-${getBadgeCategoryColor(data?.category?.color)} rounded-border-radius-small`}
              >
                <Text
                  type="labelS"
                  color={colorCategoryName}
                  className="font-semibold whitespace-nowrap"
                >
                  {data?.category?.name}
                </Text>
              </div>
            )}
            {isLoading || !data ? (
              <Skeleton className="w-[80px] h-[20px] bg-neutral-300" />
            ) : (
              <Text type="paragraphS" color="th-color-neutral-400">
                {data?.author?.name}{" "}
                {dayjs(data?.createdAt).format("DD MMM YYYY")}
              </Text>
            )}
          </div>
          {isLoading || !data ? (
            <Skeleton
              className={`w-full h-full min-h-[40px] bg-neutral-300 ${variant === "homeHighlight" ? "h-[143px] tabletSmall:h-[107px]" : "h-[120px]"}`}
            />
          ) : (
            <Text
              type="headingXS"
              color="th-color-neutral-700"
              className={`
                ${orientation === "vertical" ? `${variant === "homeHighlight" ? "h-[143px] tabletSmall:h-[107px]" : "h-[120px]"}` : "h-fit"}
              `}
            >
              {handleLimitCharacter(
                data?.title ?? "",
                orientation === "vertical" && variant === "default" ? 140 : 300
              )}
            </Text>
          )}
        </div>
      </div>
    </Link>
  );
}
