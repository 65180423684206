"use client";

import dayjs from "dayjs";
import Image from "next/image";
import ptBR from "dayjs/locale/pt-br";
import Autoplay from "embla-carousel-autoplay";
import localeData from "dayjs/plugin/localeData";

import { imageLoader } from "@aprosoja/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  Skeleton,
  Text,
} from "@aprosoja/ui";

import useHome from "../../use-home";

const MediaCarousel: React.FC = () => {
  dayjs.extend(localeData);
  dayjs.locale(ptBR);
  const { photos } = useHome();

  return (
    <section className="w-full flex flex-col items-start gap-size-x10">
      <div>
        <Text type="headingM">Mídia</Text>
      </div>
      <Carousel
        orientation="horizontal"
        className="w-full"
        plugins={[
          Autoplay({
            active: true,
            stopOnInteraction: true,
          }),
        ]}
      >
        <CarouselContent className="flex -ml-size-x8">
          {photos
            ? photos.map((photo, index) => (
                <CarouselItem
                  key={`${index}-${photo.title}`}
                  className="basis-full tabletLarge:basis-1/2 pl-size-x8"
                >
                  <div className="w-full max-w-[320px] phoneLarge:max-w-[736px] flex flex-col items-center gap-size-x2">
                    <div className="w-full h-[170px] phoneLarge:h-[368px] tabletSmall:h-[400px] relative">
                      <Image
                        fill
                        loader={imageLoader}
                        src={photo.file.fileUrl}
                        className="object-cover"
                        alt={photo.title}
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                      />
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <Text type="paragraphS">{photo.title}</Text>
                      <Text type="paragraphS" color="th-color-neutral-400">
                        {dayjs(photo.createdAt).format("DD MMM YYYY")}
                      </Text>
                    </div>
                  </div>
                </CarouselItem>
              ))
            : [1, 2, 3].map((index) => (
                <CarouselItem
                  key={index}
                  className="basis-full tabletLarge:basis-1/2 pl-size-x8"
                >
                  <Skeleton className="w-full h-[428px] bg-neutral-300" />
                </CarouselItem>
              ))}
        </CarouselContent>
      </Carousel>
    </section>
  );
};

export default MediaCarousel;
