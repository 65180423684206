"use client";
import Autoplay from "embla-carousel-autoplay";

import { CardNews } from "@aprosoja/containers";
import { useMediaQuery } from "@aprosoja/hooks";
import {
  BREAKPOINT_DESKTOP_LARGE,
  BREAKPOINT_TABLET_MEDIUM,
} from "@aprosoja/styles/breakpoints";
import { NewsType } from "@aprosoja/types";

import { Carousel, CarouselContent, CarouselItem } from "@aprosoja/ui";

type NewsCarouselProps = {
  data: NewsType[];
  className?: string;
};

const NewsCarousel = ({ data, className = "" }: NewsCarouselProps) => {
  const isTablet = useMediaQuery(`(min-width: ${BREAKPOINT_TABLET_MEDIUM})`);
  return (
    <div className={className}>
      <Carousel
        plugins={[
          Autoplay({
            active: false,
            stopOnInteraction: true,
            breakpoints: {
              [`(min-width: ${BREAKPOINT_TABLET_MEDIUM})`]: {
                active: true,
                delay: 5000,
              },
              [`(min-width: ${BREAKPOINT_DESKTOP_LARGE})`]: {
                active: false,
              },
            },
          }),
        ]}
        className="w-full"
        orientation={isTablet ? "horizontal" : "vertical"}
        opts={{
          axis: "y",
          watchDrag: false,
          breakpoints: {
            [`(min-width: ${BREAKPOINT_TABLET_MEDIUM})`]: {
              axis: "x",
              watchDrag: true,
            },
            [`(min-width: ${BREAKPOINT_DESKTOP_LARGE})`]: {
              watchDrag: false,
            },
          },
        }}
      >
        <CarouselContent className="flex tabletMedium:-ml-size-x8">
          {data.length > 0
            ? data.map((newsCard) => (
                <CarouselItem
                  key={newsCard.id}
                  className={
                    "w-full tabletMedium:flex-[0_0_392px] desktopLarge:flex-shrink tabletMedium:pl-size-x8"
                  }
                >
                  <div className="w-full ">
                    <CardNews data={newsCard} />
                  </div>
                </CarouselItem>
              ))
            : [1, 2, 3].map((index) => (
                <CarouselItem
                  key={index}
                  className={
                    "w-full tabletMedium:flex-[0_0_392px] desktopLarge:flex-shrink tabletMedium:pl-size-x8"
                  }
                >
                  <div className="w-full">
                    <CardNews isLoading />
                  </div>
                </CarouselItem>
              ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default NewsCarousel;
